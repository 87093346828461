import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useFieldArray, useForm } from 'react-hook-form'
import { Button } from '@/components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components/catalyst/dialog'

import { Description, ErrorMessage, Field, FieldGroup, Label } from '@/components/catalyst/fieldset'
import { Textarea } from '@/components/catalyst/textarea'
import { Input } from '@/components/catalyst/input'
import { useToast } from '@/components/ui/use-toast'

import { createProjectFirebaseFunction } from '@/services/Firebase'
import useStore from '@/stores/useStore.js'
import { Listbox, ListboxLabel, ListboxOption } from '@/components/catalyst/listbox.jsx'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

const repositoryDefaultFields = [
  {
    name: 'Repository URI',
    key: 'repoURI',
    requiresDefaultValue: false,
    hidden: false,
    description: 'Log into the GitHub repository and copy the URI. git@github.com:org/repo.git',
    type: 'text-short',
    placeholder: 'git@github.com:org/repo.git',
  },
  {
    name: 'Start commit hash',
    key: 'startCommitHash',
    requiresDefaultValue: false,
    hidden: false,
    description:
      'The commit hash where the iteration starts. You can get this from the GitHub repository. Looks like a key of numbers and letters.',
    type: 'text-short',
    placeholder: '1234567890abcdef',
  },
  {
    name: 'GitHub key',
    key: 'githubKey',
    requiresDefaultValue: false,
    hidden: false,
    description:
      'The key to access the GitHub repository. This is a private key. Do not share this with anyone. The key will be encrypted.',
    type: 'text-long',
    placeholder: '-----BEGIN....',
  },
]
function useCreateProject() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new project.
   */
  const createFn = async ({ organizationId, teamId, formData, usecaseFields, templateId }) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)

    try {
      // pick integer from 1 to 10 randomly
      const randomImage = Math.floor(Math.random() * 10) + 1
      // Payload should look something like this:
      const payload = {
        organizationId,
        teamId,
        projectData: {
          name: formData.name,
          description: formData.description,
          image: `${randomImage}`,
          iterationDefaults: {
            environment: formData.environment.reduce((acc, { key, value, dontEncrypt }) => {
              if (value && value !== '') {
                acc[key] = { value, dontEncrypt }
              }
              return acc
            }, {}),
            repository: formData.repository.reduce((acc, { key, value }) => {
              acc[key] = value
              return acc
            }, {}),
            usecase: usecaseFields.reduce((acc, { key, value }) => {
              acc[key] = value
              return acc
            }, {}),
            templateId: templateId || null,
          },
        },
      }
      const response = await createProjectFirebaseFunction(payload)
      setResponse(response)
      analyticsTrackEvent(ANALYTIC_EVENTS.PROJECT_CREATED, {
        organizationId,
        teamId,
        templateId,
        projectId: response?.projectId || 'N/A',
        projectName: formData.name,
      })
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateFn() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createFn, resetCreateFn }
}

/**
 * A dialog to create a new project.
 * @param {Object} props - The props for the component.
 * @param {String} props.organizationId - The ID of the organization.
 * @param {Object} props.templates - The templates for the project.
 * @param {Boolean} props.isOpen - Whether the dialog is open.
 * @param {Function} props.onClose - The function to close the dialog.
 * @param {Function} props.onCreated - The function to call when a project is created.
 * @returns {JSX.Element} The JSX element for the component.
 *
 */
export default function MNewProjectDialog({
  organizationId,
  templates,
  isOpen,
  onClose,
  onCreated = data => {
    console.log('default function called on MNewProjectDialog with data: ', data)
  },
}) {
  const { response, createFn, error: creationError, isCreating, resetCreateFn } = useCreateProject()

  const { toast } = useToast()

  const templateIds = Object.keys(templates || {})
  const [selectedTemplateId, setSelectedTemplateId] = useState(templateIds?.[0] || null)

  const getTeamsByOrgId = useStore(state => state.getTeamsByOrgId)

  const teamsForOrganization = useMemo(() => {
    return getTeamsByOrgId(organizationId)
  }, [getTeamsByOrgId, organizationId])
  const [selectedTeamId, setSelectedTeamId] = useState(teamsForOrganization[0].id)

  const selectedTemplate = useMemo(() => {
    return templates[selectedTemplateId]
  }, [selectedTemplateId, templates])

  const defaultValues = useMemo(() => {
    return {
      name: '',
      description: '',
      repository: repositoryDefaultFields,
      environment: selectedTemplate?.iterationDefaultsTemplate?.environment?.fields,
    }
  }, [selectedTemplate?.iterationDefaultsTemplate?.environment?.fields])

  const {
    register,
    control,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  })

  const { fields: repositoryFields } = useFieldArray({
    control,
    name: 'repository',
  })

  const { fields: environmentFields } = useFieldArray({
    control,
    name: 'environment',
  })

  useEffect(() => {
    resetForm(defaultValues)
  }, [defaultValues, resetForm])

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  // handle post iteration creation / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Project created successfully! 🎉',
      })
      onCreated(response?.data || {})
      resetCreateFn()
      handleClose()
    }
    if (creationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating project 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating iteration:', creationError)
      resetCreateFn()
    }
  }, [response, creationError, toast, resetCreateFn, handleClose, onCreated])
  // try creating iteration
  const handleCreation = useCallback(
    data => {
      if (!isCreating) {
        createFn({
          organizationId,
          formData: data,
          usecaseFields: selectedTemplate?.iterationDefaultsTemplate?.usecase?.fields,
          teamId: selectedTeamId,
          templateId: selectedTemplateId,
        })
      }
    },
    [
      createFn,
      isCreating,
      organizationId,
      selectedTeamId,
      selectedTemplate?.iterationDefaultsTemplate?.usecase?.fields,
      selectedTemplateId,
    ]
  )

  // prepare form data for project to be created
  const onSubmit = useCallback(
    data => {
      handleCreation(data)
    },
    [handleCreation]
  )

  return (
    <>
      <Dialog size="4xl" open={isOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Create project</DialogTitle>
          <DialogBody>
            <div className="overflow-y-auto">
              <FieldGroup className="space-y-8 pt-8">
                <div>
                  <Field>
                    <Label>Name</Label>
                    <Input
                      {...register('name', {
                        required: 'Project name is required',
                      })}
                      invalid={!!errors.name}
                    />
                  </Field>
                  {errors.name && (
                    <ErrorMessage className="mt-2">{errors.name.message}</ErrorMessage>
                  )}
                </div>
                <div>
                  <Field>
                    <Label>Description</Label>
                    <Textarea
                      {...register('description', {
                        required: 'Description is required',
                      })}
                      rows={2}
                      invalid={!!errors.description}
                    />
                  </Field>
                  {errors.description && (
                    <ErrorMessage className="mt-2 pt-0">{errors.description.message}</ErrorMessage>
                  )}
                </div>
              </FieldGroup>

              <FieldGroup className="mt-4 ">
                {Object.entries(templates).length > 1 && (
                  <Field className="flex items-center justify-between">
                    <Label>Pick a template for the project</Label>
                    <Listbox
                      onChange={e => {
                        setSelectedTemplateId(e)
                      }}
                      value={selectedTemplateId}
                      className="max-w-96"
                    >
                      {Object.entries(templates).map(([templateId, template]) => (
                        <ListboxOption key={templateId} value={templateId}>
                          <ListboxLabel>{template.name}</ListboxLabel>
                        </ListboxOption>
                      ))}
                    </Listbox>
                  </Field>
                )}
                {teamsForOrganization.length > 1 && (
                  <Field className="flex items-center justify-between">
                    <Label>Pick a team for the project</Label>
                    <Listbox
                      onChange={e => {
                        setSelectedTeamId(e)
                      }}
                      value={selectedTeamId}
                      className="max-w-48"
                    >
                      {teamsForOrganization.map(team => (
                        <ListboxOption key={team.id} value={team.id}>
                          <ListboxLabel>{team.name}</ListboxLabel>
                        </ListboxOption>
                      ))}
                    </Listbox>
                  </Field>
                )}
              </FieldGroup>

              <FieldGroup className="mt-24 space-y-8">
                <Description className="border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
                  Repository setup
                </Description>

                {repositoryFields.map((field, index) => (
                  <div className=" sm:py-4" key={index}>
                    <Field className="mt-2  sm:mt-0">
                      <Label>
                        <span>{field.name}</span>
                      </Label>
                      <Description className=" pb-2">{field.description}</Description>
                      {field.type === 'text-long' ? (
                        <Textarea
                          {...register(`repository.${index}.value`, {
                            required: field.required ? 'Field is required' : false,
                          })}
                          placeholder={field.placeholder}
                          textareaClassName="redacted-input focus:display-redacted-input"
                          invalid={!!errors.repository?.[index]?.value}
                        />
                      ) : (
                        <Input
                          {...register(`repository.${index}.value`, {
                            required: field.required ? 'Field is required' : false,
                          })}
                          placeholder={field.placeholder}
                          inputClassName="redacted-input focus:display-redacted-input "
                          invalid={!!errors.repository?.[index]?.value}
                        />
                      )}
                    </Field>
                    {errors.repository?.[index]?.value && (
                      <ErrorMessage className="mt-2 pt-0">
                        {errors.repository?.[index]?.value.message}
                      </ErrorMessage>
                    )}
                  </div>
                ))}
              </FieldGroup>

              <FieldGroup className="mt-24 space-y-8">
                <Description className="border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
                  Environment setup
                </Description>

                {environmentFields.map((field, index) => (
                  <div className="sm:py-4" key={field.key}>
                    <Field className="mt-2  sm:mt-0">
                      <Label>{field.name}</Label>
                      <Description className="mb-4">{field.description}</Description>
                      <div className="grid w-full grid-cols-12">
                        <div className="col-span-5 h-full">
                          <div className="flex h-full flex-1 items-center justify-start font-mono">
                            {field.key}
                          </div>
                        </div>
                        <div className="col-span-7 flex h-full w-full flex-1 items-start">
                          {field.type === 'text-long' ? (
                            <Textarea
                              {...register(`environment.${index}.value`, {
                                required: field.required ? 'Field is required' : false,
                              })}
                              placeholder={field.placeholder}
                              textareaClassName="redacted-input focus:display-redacted-input"
                              invalid={!!errors.environment?.[index]?.value}
                            />
                          ) : (
                            <Input
                              {...register(`environment.${index}.value`, {
                                required: field.required ? 'Field is required' : false,
                              })}
                              placeholder={field.placeholder}
                              inputClassName="redacted-input focus:display-redacted-input "
                              className="h-full "
                              invalid={!!errors.environment?.[index]?.value}
                            />
                          )}
                        </div>
                      </div>
                    </Field>
                    {errors.environment?.[index]?.value && (
                      <ErrorMessage className="mt-2 pt-0">
                        {errors.environment?.[index]?.value.message}
                      </ErrorMessage>
                    )}
                  </div>
                ))}
              </FieldGroup>
            </div>
          </DialogBody>
          <DialogActions>
            <Button disabled={isCreating} plain onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isCreating} className={isCreating ? 'bg-zinc-600' : ''} type="submit">
              {isCreating ? 'Working...' : 'Create project'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

MNewProjectDialog.propTypes = {
  organizationId: PropTypes.string,
  templates: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func,
}
