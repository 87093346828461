import { useCallback, useMemo, useState } from 'react'
import OPageWrapper from '@/components/organisms/OPageWrapper'
import useStore from '@/stores/useStore'

import OProjectsTable from '@/components/organisms/OProjectsTable'

import { Link } from '@/components/catalyst/link'
import MCardTableSkeleton from '@/components/molecules/MCardTableSkeleton'

import { ROUTES } from '@/const/routes'
import { CreateButton } from '@/components/atoms/ACreateButton.jsx'
import MCreateOrganizationDialog from '@/components/molecules/MCreateOrganizationDialog.jsx'
import MNewProjectDialog from '@/components/molecules/MCreateNewProject.jsx'
import { Button } from '@/components/catalyst/button'

const BREADCRUMBS = [
  {
    name: 'Dashboard',
    href: ROUTES.HOME,
    current: true,
  },
]

export default function PHome() {
  const [isNewOrganizationDialogOpen, setIsNewOrganizationDialogOpen] = useState(false)
  const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const projects = useStore(state => state.projects)
  const organizations = useStore(state => state.organizations)
  const isAfterInitialLoad = useStore(state => state.isAfterInitialLoad)
  const isSuperAdminFn = useStore(state => state.isSuperAdmin)
  const userRoles = useStore(state => state.userRoles)
  const getProjectBrokenDownByOrganization = useStore(
    state => state.getProjectBrokenDownByOrganization
  )

  const projectsByOrganization = useMemo(() => {
    if (projects && projects.length > 0 && organizations && organizations.length > 0) {
      return getProjectBrokenDownByOrganization()
    }
    return null
  }, [projects, getProjectBrokenDownByOrganization, organizations])

  const handleAddNewProject = useCallback(organization => {
    if (Object.keys(organization?.templates || {}).length === 0) {
      alert('No templates found for this organization')
    } else {
      setIsNewProjectDialogOpen(true)
      setSelectedOrganization(organization)
    }
  }, [])

  const isSuperAdmin = useMemo(() => {
    if (userRoles) {
      return isSuperAdminFn()
    } else {
      return false
    }
  }, [isSuperAdminFn, userRoles])

  if (!isAfterInitialLoad) {
    return (
      <OPageWrapper breadcrumbs={BREADCRUMBS}>
        <div role="status" className="px-2 py-6 lg:px-24 lg:py-12 ">
          <MCardTableSkeleton
            title="Loading projects..."
            howManyProjectsToFake={5}
            labels={['Name', 'Team', 'Updated', 'Status']}
          />
        </div>
      </OPageWrapper>
    )
  }

  if (!projectsByOrganization || projectsByOrganization.length === 0) {
    return (
      <OPageWrapper breadcrumbs={BREADCRUMBS}>
        <div className="mt-12 text-4xl font-bold tracking-tight text-gray-400 sm:text-6xl">
          No projects found
        </div>
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper breadcrumbs={BREADCRUMBS}>
      <div>
        {isSuperAdmin && (
          <div className="mt-4 flex flex-shrink-0 items-center justify-start px-2 lg:px-24 ">
            <CreateButton
              onClick={async () => {
                setIsNewOrganizationDialogOpen(true)
              }}
              label="Create new organization"
            />
          </div>
        )}
        {isSuperAdmin && (
          <div className="px-2 py-6 lg:px-24 lg:py-12 ">
            <div className="overflow-hidden rounded-lg border border-gray-200 bg-white px-4 py-5 shadow-lg sm:px-6">
              <div className="-ml-4 -mt-2  flex flex-wrap items-center justify-between py-2 sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  <h3 className="text-xl font-semibold leading-6 text-gray-900">Manage VMs</h3>
                  <p className="pt-2">Manage running VMs for all iterations</p>
                </div>
                <div className="ml-4 mt-2 flex flex-shrink-0 items-center justify-center">
                  <Button outline href={ROUTES.MANAGE_VMS}>
                    Manage VMs
                  </Button>
                  {/* <CreateButton
                    onClick={() => {
                      handleAddNewProject(organization)
                    }}
                    label="Create new project"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        )}
        {isSuperAdmin && (
          <div className="px-2 py-6 lg:px-24 lg:py-12 ">
            <div className="overflow-hidden rounded-lg border border-gray-200 bg-white px-4 py-5 shadow-lg sm:px-6">
              <div className="-ml-4 -mt-2  flex flex-wrap items-center justify-between py-2 sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  <h3 className="text-xl font-semibold leading-6 text-gray-900">
                    Iteration feedback
                  </h3>
                  <p className="pt-2">Review feedback submitted on iterations</p>
                </div>
                <div className="ml-4 mt-2 flex flex-shrink-0 items-center justify-center">
                  <Button outline href={ROUTES.ITERATION_FEEDBACK}>
                    See feedback
                  </Button>
                  {/* <CreateButton
                    onClick={() => {
                      handleAddNewProject(organization)
                    }}
                    label="Create new project"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        )}
        {projectsByOrganization.map(organization => (
          <div key={organization.id} className="px-2 py-6 lg:px-24 lg:py-12 ">
            <div className="overflow-hidden rounded-lg border border-gray-200 bg-white px-4 py-5 shadow-lg sm:px-6">
              <div className="-ml-4 -mt-2  flex flex-wrap items-center justify-between pb-6 pt-2 sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  <Link href={`/organizations/${organization.id}`}>
                    <h3 className="text-xl font-semibold leading-6 text-gray-900">
                      {projectsByOrganization.length === 1 ? 'Recent' : organization.name} projects
                    </h3>
                  </Link>
                </div>
                <div className="ml-4 mt-2 flex flex-shrink-0 items-center justify-center">
                  <CreateButton
                    onClick={() => {
                      handleAddNewProject(organization)
                    }}
                    label="Create new project"
                  />
                </div>
              </div>
              <OProjectsTable projects={organization.projects} />
            </div>
          </div>
        ))}
      </div>
      <MCreateOrganizationDialog
        isOpen={isNewOrganizationDialogOpen}
        onClose={() => {
          setIsNewOrganizationDialogOpen(false)
        }}
        onSubmitted={data => {
          console.log('data received from organization dialog', { data })
        }}
      />
      {selectedOrganization && isNewProjectDialogOpen && (
        <MNewProjectDialog
          organizationId={selectedOrganization?.id}
          templates={selectedOrganization.templates}
          isOpen={isNewProjectDialogOpen}
          onClose={() => {
            setIsNewProjectDialogOpen(false)
            setSelectedOrganization(null)
          }}
          onSubmitted={data => {
            console.log('data received from organization dialog', { data })
          }}
        />
      )}
    </OPageWrapper>
  )
}
